import React, { useRef,useState } from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import LpaFrontLineFeedback from '../../components/LPAStoreFeedback/LPAStoreAnalysisFrontline/LpaFrontLineFeedback';
import LpaSecurityFeedback from '../../components/LPAStoreFeedback/LPAStoreFeedbackSecurity/LpaSecurityFeedback';
import LpaManagementFeedback from '../../components/LPAStoreFeedback/LPAStoreFeedbackManagment/LpaManagmentFeedback.js'
import LpaCashOfficeFeedback from '../../components/LPAStoreFeedback/LPAStoreFeedbackCashOffice/LpaCashOfficeFeedback';
import LpaFindings from '../LPAStoreFeedback/LPAStoreAnalysisFindings/LPAStoreAnalysisFindings';
import { auditLogsAtom } from '../RecoilStates/auditlogsAtom';
import { auditHeaderDataAtom } from '../../components/RecoilStates/auditHeaderDataAtom';
import { lpaFrontlineDetailsState } from '../../components/RecoilStates/LpaFrontlineDetailsState';
import { lpaSecurityDetailsState } from '../../components/RecoilStates/LpaSecurityDetailsState';
import { lpaManagementDetailsState } from '../RecoilStates/LpaManagementDetailsState.js';
import { lpaCashOfficeDetailsState } from '../../components/RecoilStates/LpaCashOfficeDetailsState';
import ExportToPdf from '../../components/LPAStoreFeedback/exportToPdf.js'
import './feedbackPage.css';
import locationMarker from '../../media/locationMarker.png';
import { Chart } from "react-google-charts";
import { MapContainer, TileLayer, Marker, Popup} from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';


const FeedbackPage = () => {
  const chartRef = useRef(null);
  const [markerPopup, setMarkerPopup] = useState(false);
  const mapRef = useRef(null);
  
  const { auditCode } = useParams();
  const auditLogsData = useRecoilValue(auditLogsAtom);
  const auditHeaderData = useRecoilValue(auditHeaderDataAtom);
  const fronlineScore = useRecoilValue(lpaFrontlineDetailsState);
  const securityScore = useRecoilValue(lpaSecurityDetailsState);
  const managementScore = useRecoilValue(lpaManagementDetailsState);
  const cashOfficeScore = useRecoilValue(lpaCashOfficeDetailsState);
  
  const frontlineScoreArray = Array.isArray(fronlineScore) ? fronlineScore : [];
  const filteredFrontlineDetails = frontlineScoreArray.filter(details => details.auditCode === auditCode);

  const securityScoreArray = Array.isArray(securityScore) ? securityScore : [];
  const filteredSecurityDetails = securityScoreArray.filter(details => details.auditCode === auditCode);

  const managementScoreArray = Array.isArray(managementScore) ? managementScore : [];
  const filteredManagementDetails = managementScoreArray.filter(details => details.auditCode === auditCode);

  const cashOfficeScoreArray = Array.isArray(cashOfficeScore) ? cashOfficeScore : [];
  const filteredCashOfficeDetails = cashOfficeScoreArray.filter(details => details.auditCode === auditCode);

  const filteredAuditCodeData = auditLogsData.filter(details => details.auditCode === auditCode);

  // Safely handle all score calculations with default values
  const frontlineTotalScore = filteredFrontlineDetails.length > 0 ? parseInt(filteredFrontlineDetails[0].totalScore) : 0;
  const securityTotalScore = filteredSecurityDetails.length > 0 ? parseInt(filteredSecurityDetails[0].totalScore) : 0;
  const managementTotalScore = filteredManagementDetails.length > 0 ? parseInt(filteredManagementDetails[0].totalScore) : 0;
  const cashOfficeTotalScore = filteredCashOfficeDetails.length > 0 ? parseInt(filteredCashOfficeDetails[0].totalScore) : 0;

  // console.log(filteredManagementDetails);

  let securityColor;
  let frontlineColor;
  let managementColor;
  let cashOfficeColor;

  // Determine colors based on scores
  if (frontlineTotalScore <= 50) {
    frontlineColor = "#D18678"; // Red
  } else if (frontlineTotalScore > 50 && frontlineTotalScore <= 70) {
    frontlineColor = "#78ABD1"; // Blue
  } else {
    frontlineColor = "#5CB268"; // Green
  }

  if (securityTotalScore <= 50) {
    securityColor = "#D18678"; // Red
  } else if (securityTotalScore > 50 && securityTotalScore <= 70) {
    securityColor = "#78ABD1"; // Blue
  } else {
    securityColor = "#5CB268"; // Green
  }

  if (managementTotalScore <= 50) {
    managementColor = "#D18678"; // Red
  } else if (managementTotalScore > 50 && managementTotalScore <= 70) {
    managementColor = "#78ABD1"; // Blue
  } else {
    managementColor = "#5CB268"; // Green
  }

  if (cashOfficeTotalScore <= 50) {
    cashOfficeColor = "#D18678"; // Red
  } else if (cashOfficeTotalScore > 50 && cashOfficeTotalScore <= 70) {
    cashOfficeColor = "#78ABD1"; // Blue
  } else {
    cashOfficeColor = "#5CB268"; // Green
  }

  const data = [
    [
      "Element",
      "Total Percentage",
      { role: "style" },
      {
        sourceColumn: 0,
        role: "annotation",
        type: "string",
        calc: "stringify",
      },
    ],
    ["Frontline", frontlineTotalScore, frontlineColor, `${frontlineTotalScore}%`],
    ["Security", securityTotalScore, securityColor, `${securityTotalScore}%`],
    ["Management", managementTotalScore, managementColor, `${managementTotalScore}%`],
    ["Cash Office", cashOfficeTotalScore, cashOfficeColor, `${cashOfficeTotalScore}%`],
  ];

  const options = {
    title: "Shrinkage Barometer",
    width: 600,
    height: 400,
    bar: { groupWidth: "95%" },
    legend: { position: "none" },
    vAxis: {
      format: "#%", // Format values as percentages
      textStyle: {
        color: "black", // Change the color of the labels here
        textAlign: "start", // Align labels to the left
      },
    },
    hAxis: {
      textStyle: {
        color: "black", // Change the color of the labels here
        textAlign: "start", // Align labels to the left
      },
      ticks: [], // Remove x-axis ticks
    },
  };

  const customIcon = L.icon({
    iconUrl: locationMarker,
    iconSize: [14, 18], // Adjust the size as needed
  });

  return (
    <div className="feedbackContainer">
      <div className="feedBackheaderTitle">
        <h3>Site Audit</h3>
      </div>
      <div className="exportToPDFCon">
        <ExportToPdf mapRef={mapRef} chartRef={chartRef} />
      </div>
      <div className="headerTableCont">
        <div className="headerTable">
          <div className="firstHeaderColumn">
            <div className="firstColumnFirstRow">Site</div>
            <div className="firstColumnSecondRow">Completed By</div>
            <div className="firstColumnThirdRow">Date</div>
          </div>
          <div className="secondHeaderColumn">
            <div className="secondColumnFirstRow">
              <label>{filteredAuditCodeData[0]?.site}</label>
            </div>
            <div className="secondColumnSecondRow">
              <label>{filteredAuditCodeData[0]?.user}</label>
            </div>
            <div className="secondColumnThirdRow">
              <label>{filteredAuditCodeData[0]?.completionDate}</label>
            </div>
          </div>
          <div className="thirdHeaderColumn">
            <div className="thirdColumnFirstRow">
              Score Card
              <label>{filteredAuditCodeData[0]?.averagePercentage}%</label>
            </div>
          </div>
        </div>
      </div>
      <div id="mapAndChartContainer" className="mapAndGraph">
        <div ref={mapRef} id="mapContainer" className="mapContainer">
          <MapContainer
            center={[filteredAuditCodeData[0].latitude, filteredAuditCodeData[0].longitude]}
            zoom={18}
            style={{ height: "400px", width: "100%" }}
          >
            <TileLayer
              attribution="Map data &copy; OpenStreetMap contributors"
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <Marker position={[filteredAuditCodeData[0].latitude, filteredAuditCodeData[0].longitude]} icon={customIcon}>
              <Popup onClose={() => setMarkerPopup(false)}>
                <div>
                  Latitude: {[filteredAuditCodeData[0].latitude]}<br />
                  Longitude: {[filteredAuditCodeData[0].longitude]}<br />
                </div>
              </Popup>
            </Marker>
          </MapContainer>
        </div>
        <div ref={chartRef} id="chartContainer" className="chartContainer">
          <Chart chartType="BarChart" width="80%" height="400px" data={data} options={options} />
        </div>
      </div>
      <LpaFindings />
      <LpaFrontLineFeedback />
      <LpaSecurityFeedback />
      <LpaManagementFeedback />
      <LpaCashOfficeFeedback />
      <div className="tableFeedback">
        <div className="firstColumn">
          <div className="userRow">{auditLogsData[0]?.user}</div>
          <div className="dateRow">Date: {auditLogsData[0]?.completionDate}</div>
        </div>
        <div className="secondColumn">
          <div className="headerRow">
            <label className="headerTile">Total LPA Score</label>
          </div>
          <div className="firstRow">
            {filteredFrontlineDetails.length > 0 && (
              <label className="rowData">Frontline = {filteredFrontlineDetails[0].totalScore}%</label>
            )}
          </div>
          <div className="secondRow">
            {filteredSecurityDetails.length > 0 && (
              <label className="rowData">Security = {filteredSecurityDetails[0].totalScore}%</label>
            )}
          </div>
          <div className="thirdRow">
            {filteredManagementDetails.length > 0 && (
              <label className="rowData">Management= {filteredManagementDetails[0].totalScore}%</label>
            )}
          </div>
          <div className="forthRow">
            {filteredCashOfficeDetails.length > 0 && (
              <label className="rowData">Cash Office= {filteredCashOfficeDetails[0].totalScore}%</label>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeedbackPage;
