import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link, useNavigate } from "react-router-dom";
import ReactPaginate from 'react-paginate';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import '../../styles/custom.css';
import './StoreAudit.css';
import { format, parse } from 'date-fns';
import { DateRangePicker } from 'rsuite';
import { auditHeaderDataAtom } from '../../components/RecoilStates/auditHeaderDataAtom';
import { useParams } from "react-router-dom";
import { lpaFrontlineQuestionsState } from '../../components/RecoilStates/LpaFrontlineQuestionsState';
import { lpaFrontlineDetailsState } from '../../components/RecoilStates/LpaFrontlineDetailsState';

import { lpaSecurityQuestionsState } from '../../components/RecoilStates/LpaSecurityQuestionsState';
import { lpaSecurityDetailsState } from '../../components/RecoilStates/LpaSecurityDetailsState';

import { lpaManagementQuestionsState } from '../../components/RecoilStates/LpaManagementQuestionsState';
import { lpaManagementDetailsState } from '../../components/RecoilStates/LpaManagementDetailsState';

import { lpaCashOfficeQuestionsState } from '../../components/RecoilStates/LpaCashOfficeQuestionsState';
import { lpaCashOfficeDetailsState } from '../../components/RecoilStates/LpaCashOfficeDetailsState';

import { auditLogsAtom } from '../../components/RecoilStates/auditlogsAtom';

const StoreAudit = () => {
  const setLpaFrontlineQuestions = useSetRecoilState(lpaFrontlineQuestionsState);
  const setLpaSecurityQuestions = useSetRecoilState(lpaSecurityQuestionsState);
  const setLpaManagementQuestions = useSetRecoilState(lpaManagementQuestionsState);
  const setLpaCashOfficeQuestions = useSetRecoilState(lpaCashOfficeQuestionsState);

  const [showSuperAdminDashboard, setShowSuperAdminDashboard]= useState(false); 
  const [showAdminDashboard, setShowAdminDashboard] = useState(false);
  const [showManagerDashboard, setShowManagerDashboard] = useState(false);

  const [auditLogs, setAuditLogs] = useRecoilState(auditLogsAtom);
  const [isLoading, setIsLoading] = useState(true);
  const [clickedRowUrl, setClickedRowUrl] = useState(null);
  const [isDataReady, setIsDataReady] = useState(false);

  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 10;

  const [sites, setSites] = useState([]);
  const [selectedSite, setSelectedSite] = useState('');
  const [isSearchDropdownOpen, setIsSearchDropdownOpen] = useState(false);
  const [dateRange, setDateRange] = useState([null, null]);
  const [searchCompletedBy, setSearchCompletedBy] = useState('');

  const navigate = useNavigate();

  const setLpaFrontlineDetails = useSetRecoilState(lpaFrontlineDetailsState);
  const setLpaSecurityDetails = useSetRecoilState(lpaSecurityDetailsState);
  const setLpaManagementDetails = useSetRecoilState(lpaManagementDetailsState);
  const setLpaCashOfficeDetails = useSetRecoilState(lpaCashOfficeDetailsState);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://myworxit.co.za/api/audits/viewAuditLogs.php');
        const newData = response.data.records;
        if (Array.isArray(newData)) {
          setAuditLogs(newData);
        }
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, [setAuditLogs]);

  useEffect(() => {
    const fetchAllData = async () => {
      try {
        await Promise.all([
          fetchFrontlineQuestions(),
          fetchFrontlineData(),
          fetchSecurityQuestions(),
          fetchSecurityData(),
          fetchManagementQuestions(),
          fetchManagementData(),
          fetchCashOfficeQuestions(),
          fetchCashOfficeData(),
          fetchSitesData()
        ]);
        setIsDataReady(true);
      } catch {
        setIsDataReady(true);
      }
    };

    fetchAllData();
  }, []);

  useEffect(()=>{
      
    const role = localStorage.getItem('role');
    if(role === 'superAdmin'){
      setShowSuperAdminDashboard(true)
    }
    if(role === 'Admin'){
      setShowAdminDashboard(true)
    }
    if(role === 'Manager'){
      setShowManagerDashboard(true)
    }
    },[])

  const fetchFrontlineQuestions = async () => {
    try {
      const response = await axios.get('https://myworxit.co.za/api/audits/frontlineSectionQuestions.php');
      const newData = response.data.records;
      setLpaFrontlineQuestions((prevLpaData) => ({
        ...prevLpaData,
        LPAQuestions: newData,
      }));
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const fetchFrontlineData = async () => {
    try {
      const response = await axios.get('https://myworxit.co.za/api/audits/frontlineSectionTableDetails.php');
      const newData = response.data.records;
      if (Array.isArray(newData)) {
        setLpaFrontlineDetails(newData);
      }
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setIsLoading(false);
    }
  };

  const fetchSecurityQuestions = async () => {
    try {
      const response = await axios.get('https://myworxit.co.za/api/audits/securitySectionQuestions.php');
      const newData = response.data.records;
      setLpaSecurityQuestions((prevLpaData) => ({
        ...prevLpaData,
        LPAQuestions: newData,
      }));
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const fetchSecurityData = async () => {
    try {
      const response = await axios.get('https://myworxit.co.za/api/audits/securitySectionTableDetails.php');
      const newData = response.data.records;
      if (Array.isArray(newData)) {
        setLpaSecurityDetails(newData);
      }
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setIsLoading(false);
    }
  };

  const fetchManagementQuestions = async () => {
    try {
      const response = await axios.get('https://myworxit.co.za/api/audits/managementSectionQuestions.php');
      const newData = response.data.records;
      setLpaManagementQuestions((prevLpaData) => ({
        ...prevLpaData,
        LPAQuestions: newData,
      }));
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const fetchManagementData = async () => {
    try {
      const response = await axios.get('https://myworxit.co.za/api/audits/managementSectionTableDetails.php');
      const newData = response.data.records;
      if (Array.isArray(newData)) {
        setLpaManagementDetails(newData);
      }
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setIsLoading(false);
    }
  };

  const fetchCashOfficeQuestions = async () => {
    try {
      const response = await axios.get('https://myworxit.co.za/api/audits/cashOfficeSectionQuestions.php');
      const newData = response.data.records;
      setLpaCashOfficeQuestions((prevLpaData) => ({
        ...prevLpaData,
        LPAQuestions: newData,
      }));
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const fetchCashOfficeData = async () => {
    try {
      const response = await axios.get('https://myworxit.co.za/api/audits/cashOfficeSectionTableDetails.php');
      const newData = response.data.records;
      if (Array.isArray(newData)) {
        setLpaCashOfficeDetails(newData);
      }
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setIsLoading(false);
    }
  };

  const fetchSitesData = async () => {
    try {
      const response = await axios.get('https://myworxit.co.za/api/audits/viewSites.php');
      const newData = response.data.records;
      
      setSites(newData);
      
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setIsLoading(false);
    }
  };

  if (!isDataReady) {
    return <div>Loading...</div>;
  }

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const handleClick = (auditCode) => {
    const url = `/feedback/${auditCode}`;
    setClickedRowUrl(url);
    navigate(url); // If you want to navigate to the new URL
};

  const auditLogsEmpty = () => auditLogs.every(data => !data);

  if (!Array.isArray(auditLogs)) {
    return <div>Loading...</div>;
  }

  const parseDateString = (dateString) => {
    const parts = dateString.split(' at ');
    const formattedDate = parts[0].replace('Friday, ', '').replace(/(st|nd|rd|th),/, ',') + ' ' + parts[1];
    return new Date(formattedDate);
  };

  const handleMaintenceClick = () => {
    navigate('/auditMaintenance');
  };

  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
  };


  const filteredAuditLogs = auditLogs.filter((log) => {
    const searchSiteLower = selectedSite.toLowerCase();
    const searchCompletedByLower = searchCompletedBy.toLowerCase();
  
    // Check if log.site contains the search term (case insensitive)
    const siteMatch = log.site.toLowerCase().includes(searchSiteLower);
  
    // Check if log.user contains the search term (case insensitive)
    const completedByMatch = log.user.toLowerCase().includes(searchCompletedByLower);
  
    // Determine the date range to filter logs
    let fromDate = '';
    let toDate = '';
  
    if (!dateRange || !dateRange[0]) {
      // If no date range is specified, fetch reports for the last 30 days
      const thirtyDaysAgo = new Date();
      thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
      fromDate = format(thirtyDaysAgo, 'yyyy-MM-dd 00:00:00');
      toDate = format(new Date(), 'yyyy-MM-dd 23:59:59');
    } else {
      fromDate = format(dateRange[0], 'yyyy-MM-dd 00:00:00');
      toDate = format(dateRange[1], 'yyyy-MM-dd 23:59:59');
    }
  
    // Parse log.completionDate and format it for comparison
    let dateMatch = false;
    if (log.completionDate) {
      const formattedLogDate = format(parse(log.completionDate, 'yyyy-MM-dd HH:mm:ss', new Date()), 'yyyy-MM-dd HH:mm:ss');
      dateMatch = formattedLogDate >= fromDate && formattedLogDate <= toDate;
    }
  
    return siteMatch && dateMatch && completedByMatch;
  });
  

  const pageCount = Math.ceil(filteredAuditLogs.length / itemsPerPage);
  const currentItems = filteredAuditLogs.slice(
    currentPage * itemsPerPage,
    currentPage * itemsPerPage + itemsPerPage
  );

  const handleClearSearchBtn = () =>{
    setSelectedSite('');
    setDateRange([null, null]);
    setSearchCompletedBy('');
    setIsSearchDropdownOpen(false)
  }

  const handleSearchInputChange = (e) => {
    setSelectedSite(e.target.value);
    setIsSearchDropdownOpen(true);

  };

  const handleSearchOptionClick = (site) => {
    setSelectedSite(site);
    setIsSearchDropdownOpen(false);
  };

  const filteredSites = sites.filter((item) =>
    item.site.toLowerCase().includes(selectedSite.toLowerCase())
  );



  return (
    <div className='conatinerBackground'>
      {showManagerDashboard || showAdminDashboard || showSuperAdminDashboard ? (
      <div className='btnContainer'>
        <div className='auditMaintenBtnContainer'>
          <button className='auditMaintenBtn' onClick={handleMaintenceClick}>Audit Maintenance</button>
        </div>
        </div> 
      ) : null}
      {/* <div className='btnContainer'>
        <div className='auditMaintenBtnContainer'>
          <button className='auditMaintenBtn' onClick={handleMaintenceClick}>Audit Maintenance</button>
        </div>
      */}
      <div className="search-header">
      {/* <select 
      className='siteDropdown'
        value={selectedSite}
        onChange={(e) => setSelectedSite(e.target.value)}
      >
        <option value="" disabled>Select a site</option>
        {sites.map((site) => (
          <option key={site.id} value={site.site}>
            {site.site}
          </option>
        ))}
      </select> */}
          
            <div className='dropdownSearchCont'>
              <input
                className='siteDropdown'
                type="text"
                value={selectedSite}
                onChange={handleSearchInputChange}
                onFocus={() => setIsSearchDropdownOpen(true)}
                placeholder="Search site..."
              />
              {isSearchDropdownOpen && (
                <div className="searchDropdown">
                  {filteredSites.length > 0 ? (
                    filteredSites.map((item) => (
                      <div
                        key={item.id}
                        className="dropdown-item"
                        onClick={() => handleSearchOptionClick(item.site)}
                      >
                        {item.site}
                      </div>
                    ))
                  ) : (
                    <div className="dropdown-item">No results found</div>
                  )}
                </div>
              )}
            </div>
        <DateRangePicker
                  className='dateInput'
                  value={dateRange}
                  onChange={(value) => {
                    setDateRange(value);
                  }}
                  //onClean={resetData}
                  placeholder={"Search Date Range"}
                />
        <input
          type="text"
          placeholder="Search by completed by"
          value={searchCompletedBy}
          onChange={(e) => setSearchCompletedBy(e.target.value)}
        />
        <button onClick={handleClearSearchBtn} className='clearSearchbtn'>Clear</button>
      </div>
      <div className='passedTableTitle'>
        <h4 className='passedTitleLbl'>Past Audits</h4>
      </div>
      <div className='passedTable'>
        {!auditLogsEmpty() ? (
          <>
            <table>
              <thead>
                <tr>
                  <th className='auditCodeColumnWidth'>Audit Code</th>
                  <th className='compByColumnWidth'>Completed By</th>
                  <th className='siteColumnWidth'>Site</th>
                  <th className='siteColumnWidth'>Audit %</th>
                  <th className='dateColumnWidth'>Completion Date</th>
                  <th className='latitudeColumnWidth'>Co Ordinates</th>
                </tr>
              </thead>
              <tbody>
                {currentItems
                  .sort((a, b) => parseDateString(b.completionDate) - parseDateString(a.completionDate))
                  .map((details, index) => (
                    <tr
                      key={index}
                      className='clickable-row'
                      onClick={() => handleClick(details.auditCode)}
                    >
                      <td className='auditCodeColumnWidth'>{details.auditCode}</td>
                      <td className='compByColumnWidth'>{details.user}</td>
                      <td className='siteColumnWidth'>{details.site}</td>
                      <td className='siteColumnWidth'>{details.averagePercentage}</td>
                      <td className='dateColumnWidth'>{details.completionDate}</td>
                      <td className='latitudeColumnWidth'>{details.latitude}, {details.longitude}</td>
                    </tr>
                  ))}
              </tbody>
             
            </table>
            <ReactPaginate
              previousLabel={'<<'}
              nextLabel={'>>'}
              breakLabel={'...'}
              breakClassName={'break-me'}
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageClick}
              containerClassName={'pagination'}
              subContainerClassName={'pages pagination'}
              activeClassName={'active'}
            />
          </>
        ) : (
          <div>
            <label>No Data</label>
          </div>
        )}
      </div>
    </div>
  );
};

export default StoreAudit;
