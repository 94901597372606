import React, { useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import domToImage from 'dom-to-image';
import { useRecoilValue } from "recoil";
import ClipLoader from "react-spinners/ClipLoader";

import { lpaFindingsState } from "../RecoilStates/LpaFindingsState";
import { lpaFrontlineDetailsState } from "../RecoilStates/LpaFrontlineDetailsState";
import { lpaSecurityDetailsState } from '../RecoilStates/LpaSecurityDetailsState';
import { lpaManagementDetailsState } from "../RecoilStates/LpaManagementDetailsState";
import { lpaCashOfficeDetailsState } from '../RecoilStates/LpaCashOfficeDetailsState';
import { auditLogsAtom } from "../RecoilStates/auditlogsAtom";

import failCircleUrl from '../../media/failCircle.png';
import passCircleUrl from '../../media/passCircle.png';
import naCircleUrl from '../../media/naCircle.png';

const ExportToPDF = ({ chartRef, mapRef }) => {
  const { auditCode } = useParams();  
  const auditLogsData = useRecoilValue(auditLogsAtom);
  const findingsDetails = useRecoilValue(lpaFindingsState);
  const frontlineDetails = useRecoilValue(lpaFrontlineDetailsState);
  const securityDetails = useRecoilValue(lpaSecurityDetailsState);
  const managementDetails = useRecoilValue(lpaManagementDetailsState);
  const cashOfficeDetails = useRecoilValue(lpaCashOfficeDetailsState);
  const [isLoading, setIsLoading] = useState(false);

  // Safely access properties and apply filters
  const filteredAuditLogs = auditLogsData?.filter(details => details.auditCode === auditCode) || [];
  const filteredFindingDetails = findingsDetails?.filter(details => details.auditCode === auditCode) || [];
  const filteredFrontlineDetails = frontlineDetails.responses?.filter(details => details.auditCode === auditCode) || [];
  const filteredSecurityDetails = securityDetails?.filter(details => details.auditCode === auditCode) || [];
  const filteredManagementDetails = managementDetails.responses?.filter(details => details.auditCode === auditCode) || [];
  const filteredCashOfficeDetails = cashOfficeDetails?.filter(details => details.auditCode === auditCode) || [];

  // Optionally, check if data is still loading
  const isLoadingData = !filteredFrontlineDetails.length && 
                        !filteredSecurityDetails.length && 
                        !filteredManagementDetails.length && 
                        !filteredCashOfficeDetails.length;

  if (isLoadingData) {
    return <div>Loading...</div>;
  }

  const handleExportToPDFTest = async () => {
    setIsLoading(true);

    let chartImageUrl, mapImageUrl;

    try {
      console.log("Reached domToImage conversion for chart.");
      chartImageUrl = await domToImage.toPng(chartRef.current);
      console.log("Chart image URL generated:", chartImageUrl);
      
      mapImageUrl = await domToImage.toPng(mapRef.current);
    } catch (error) {
        console.error('Error generating images:', error);
        setIsLoading(false);
        return;
    }

    const filteredAuditCode = filteredAuditLogs.map(({ auditCode}) => auditCode).join(', ');
    const filteredAuditSite = filteredAuditLogs.map(({ site}) => site).join(', ');
    const filteredAuditUser = filteredAuditLogs.map(({ user}) => user).join(', ');
    const filteredAuditCompletionDate = filteredAuditLogs.map(({ completionDate}) => completionDate).join(', ');
    const filteredAuditLatitude = filteredAuditLogs.map(({ latitude }) => latitude).join(', ');
    const filteredAuditLongitude = filteredAuditLogs.map(({ longitude }) => longitude).join(', ');
    const filteredAuditAveragePercentage = filteredAuditLogs.map(({ averagePercentage }) => averagePercentage).join(', ');
    const dateTimeString = filteredAuditCompletionDate;
    const dateOnly = new Date(dateTimeString).toISOString().split('T')[0];

    try {
        const response = await fetch(`https://myworxit.co.za/api/audits/exportAuditToPDF/exportAuditToPDF.php?downloadPDF=true`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                filteredAuditCode,
                filteredAuditSite,
                filteredAuditUser,
                filteredAuditCompletionDate,
                filteredAuditLatitude,
                filteredAuditLongitude,
                filteredAuditAveragePercentage,
                chartImageUrl,
                mapImageUrl,
                failCircleUrl,
                passCircleUrl,
                naCircleUrl,
                filteredFindingDetails: filteredFindingDetails,
                filteredFrontlineDetails: filteredFrontlineDetails,
                filteredSecurityDetails: filteredSecurityDetails,
                filteredManagementDetails: filteredManagementDetails,
                filteredCashOfficeDetails: filteredCashOfficeDetails,
            })
        });
        if (response.ok) {
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${filteredAuditSite}_${dateOnly}.pdf`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        } else {
            console.error('Failed to download PDF');
        }
    } catch (error) {
        console.error('Error:', error);
    } finally {
        console.log("Setting isLoading to false");
        setIsLoading(false);
    }
  };

  return (
    <div>
      {isLoading ? (
        <div className="exportPDFBtnLoader">
          <ClipLoader label="Loading Spinner" color={"#ffffff"} loading={isLoading} size={32} />
        </div>
      ) : (
        <button className="exportPDFBtn" onClick={handleExportToPDFTest}>
          Export to PDF
        </button>
      )}        
    </div>
  );
};

export default ExportToPDF;
